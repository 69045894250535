import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Controller from "../components/slices/controller"

export default function ThankYou({ data }) {
  const {
    meta_title,
    meta_description,
  } = data.allPrismicPage.edges[0].node.data
  return (
    <Layout>
      <Controller
        metaTitle={meta_title}
        metaDescription={meta_description}
        sectionTitle={meta_title}
        body={data.allPrismicPage.edges[0].node.data.body}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allPrismicPage(filter: { uid: { eq: "thank-you" } }) {
      edges {
        node {
          uid
          data {
            meta_title
            meta_description
            body {
              ... on PrismicPageBodyInfoContainer {
                slice_label
                items {
                  copy {
                    html
                  }
                  link
                  button_text
                  info_link
                  info_image {
                    url
                  }
                }
              }
              ... on PrismicPageBodyProfiles {
                slice_label
                items {
                  name_of_person
                  portrait_of_person {
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  biography {
                    html
                  }
                }
              }
              ... on PrismicPageBodyFeatured {
                slice_label
                primary {
                  testimonial_quote {
                    html
                  }
                  featured_image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 100) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicPageBodyTopic {
                slice_label
                primary {
                  topic_name
                  nav_redirect_link
                  nav_redirect_title
                }
              }
              ... on PrismicPageBodySlider {
                slice_label
                primary {
                  title_of_slide
                  navigation_action
                  nav_link
                }
                items {
                  photos {
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicPageBodyHomePhotoGrid {
                slice_type
                slice_label
                items {
                  name_of_photo
                  grid_photo {
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicPageBodyCallToAction {
                slice_label
                primary {
                  call_to_action_message {
                    text
                  }
                  nav_link
                  call_to_action_copy
                }
              }
            }
          }
        }
      }
    }
  }
`
